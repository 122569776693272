<template>
  <v-card
    flat
    draggable
    @dragstart="dragstart($event)"
    @dragend="addItemDrop()">
    <v-card-text class="pa-0">
      <div
        class="d-flex element-item align-center justify-center">
        <div class="element-handle">
          <v-icon size="20">
            mdi-drag
          </v-icon>
        </div>
        <div class="d-flex flex-column">
          <v-icon class="mb-1">
            {{ icon }}
          </v-icon>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <small
                v-bind="attrs"
                class="element-name caption"
                v-on="on">
                {{ name }}
              </small>
            </template>
            {{ name }}
          </v-tooltip>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      default: 'Untitled'
    },
    icon: {
      type: String,
      default: 'mdi-pencil'
    },
    previewWidth: {
      type: String,
      default: '50px'
    },
    previewHeight: {
      type: String,
      default: '50px'
    }
  },
  methods: {
    addItemDrop () {
      const preview = document.getElementById('snt-drag-preview-onetime')
      preview.remove()
      this.$emit('dragend:item')
    },
    addItem () {
      this.$emit('click:item')
    },
    dragstart (e) {
      const div = document.createElement('dev')
      div.id = 'snt-drag-preview-onetime'
      div.style.position = 'absolute'
      div.style.top = '0px'
      div.style.right = '0px'
      div.style.background = '#ffffff'
      div.style.border = '1px dashed #1ba7e1'
      div.style.width = this.previewWidth
      div.style.height = this.previewHeight
      div.style.zIndex = '-1'
      e.target.appendChild(div)
      e.dataTransfer.setDragImage(div, 0, 0)
    }
  }
}
</script>
<style scoped>
  #coverup {
    background: white;
    width: 170px;
    height: 100px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }
  .element-item {
    /* background-color: pink; */
    background: #F5F5F5;
    border: 1px solid #D1D1D1;
    box-sizing: border-box;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    padding: 16px 8px;
  }
  .element-handle {
    position: absolute;
    height: 16px;
    line-height: 16px;
    left: 6px;
    z-index: 1;
    cursor: move;
  }
  .element-name {
    text-overflow: ellipsis;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
</style>
<style>
  .element-handle .v-icon.v-icon.v-icon--link {
    cursor: move !important;
  }
</style>
