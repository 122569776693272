var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      attrs: { flat: "", draggable: "" },
      on: {
        dragstart: function($event) {
          return _vm.dragstart($event)
        },
        dragend: function($event) {
          return _vm.addItemDrop()
        }
      }
    },
    [
      _c("v-card-text", { staticClass: "pa-0" }, [
        _c(
          "div",
          { staticClass: "d-flex element-item align-center justify-center" },
          [
            _c(
              "div",
              { staticClass: "element-handle" },
              [_c("v-icon", { attrs: { size: "20" } }, [_vm._v(" mdi-drag ")])],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex flex-column" },
              [
                _c("v-icon", { staticClass: "mb-1" }, [
                  _vm._v(" " + _vm._s(_vm.icon) + " ")
                ]),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "small",
                              _vm._g(
                                _vm._b(
                                  { staticClass: "element-name caption" },
                                  "small",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(" " + _vm._s(_vm.name) + " ")]
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [_vm._v(" " + _vm._s(_vm.name) + " ")]
                )
              ],
              1
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }